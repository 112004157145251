import React, { Fragment, useEffect } from 'react';
import { ReactTagManager } from 'react-gtm-ts';

// Lazy Modules
import { isDesktop, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AppLoader from './components/AppLoader';

// Components
import AsyncComponent from './components/hoc/AsyncComponent';
import SnackBarWrapper from './components/hoc/SnackBarWrapper';
import ForgotPassword from './containers/public/forgot-password/ForgotPassword';

// Pages
import LandingPage from './containers/public/landing/LandingPage';
import Login from './containers/public/login/Login';
import Signup from './containers/public/signup/Signup';
import SignupSegway from './containers/public/signup/SignupSegway';
import SupportArtist from './containers/public/signup/SupportArtist';
import { setCurrency } from './redux/currency/CurrencyActions';

// Services
import { HIDE_NOTIF } from './redux/notification/NotificationActions';
import { getLoggedInUser } from './redux/user/UserActions';
import { getUSDToFenixPrice } from './services/apiEndpoint';

const PublicAsync = AsyncComponent(() => import('./containers/public/index'));
const ArtistAsync = AsyncComponent(() => import('./containers/artist/layout/Layout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (localStorage.getItem('ARTPWATKEN') ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
);
export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!localStorage.getItem('ARTPWATKEN') ? <Component {...props} /> : <Redirect to={{ pathname: '/artist/dashboard', state: { from: props.location } }} />)}
  />
);
const App = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loading);
  const notification = useSelector((state) => state.notification);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      if (process.env.NODE_ENV === 'production') {
        window.location.reload();
      }
    });

    if (isDesktop) {
      document.body.classList.add('app-desktop-view');
    }

    getConversionData();

    return () => {
      document.body.classList.remove('app-desktop-view');
      window.removeEventListener('resize', () => {});
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('ARTPWATKEN')) {
      dispatch(getLoggedInUser());
    }
  }, []);
  const getConversionData = async () => {
    try {
      await getUSDToFenixPrice().then(({ data }) => {
        dispatch(setCurrency({ usdToFenix: data }));
      });
    } catch (err) {}
  };
  const handleClose = () => {
    dispatch({
      type: HIDE_NOTIF,
      payload: {},
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV === 'production') {
      ReactTagManager.init({
        code: 'GTM-WPCJW73V',
      });
    }
  });

  return (
    <Fragment>
      {loader.isLoading && <AppLoader title={loader.title} />}
      <Switch>
        <Route exact path="/">
          {/* <Redirect to="/new" /> */}
          {!isMobile ? <Redirect to={{ pathname: '/artist/welcome', state: { from: props.location } }} /> : <LandingPage />}
        </Route>

        {/* <Route path={`/public`} component={PublicAsync} /> */}
        <Route path={`/artist`} component={ArtistAsync} />
        <PublicRoute exact path={`/login`} component={Login} />
        <PublicRoute exact path={`/signup`} component={Signup} />
        {/* <Route exact path={`/forgot-password`} component={ForgotPassword} /> */}
        {/* <Route exact path={`/signup-segway`} component={SignupSegway} /> */}
        {/* <Route exact path={`/support-artist`} component={SupportArtist} /> */}

        <Route path={'**'} component={LandingPage} />
      </Switch>
      <SnackBarWrapper message={notification.message} action={notification.action} display={notification.display} onClose={handleClose} duration={notification.duration} />
    </Fragment>
  );
};

export default withRouter(App);
